<script>
import appConfig from "../../../app.config";
import SwiperCore, { Thumbs, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Thumbs, Navigation, Pagination]);
export default {
  page: {
    title: "Starter",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Starter",
      items: [
        {
          text: "Pages",
          href: "/",
        },
        {
          text: "Starter",
          active: true,
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    monthly() {
      const highlightedItems = document.querySelectorAll(".month");

      highlightedItems.forEach(function (userItem) {
        userItem.style.display = "block";
      });
      const highlightedItems2 = document.querySelectorAll(".annual");

      highlightedItems2.forEach(function (userItem) {
        userItem.style.display = "none";
      });
    },
    anually() {
      const highlightedItems = document.querySelectorAll(".month");

      highlightedItems.forEach(function (userItem) {
        userItem.style.display = "none";
      });
      const highlightedItems2 = document.querySelectorAll(".annual");

      highlightedItems2.forEach(function (userItem) {
        userItem.style.display = "block";
      });
    },
  },
  mounted() {
    this.monthly();
    document.getElementById("plan-switch").addEventListener("change", () => {
      if (document.getElementById("plan-switch").checked == true) {
        this.anually();
      } else if (document.getElementById("plan-switch").checked == false) {
        this.monthly();
      }
    });
    window.addEventListener("scroll", function (ev) {
      ev.preventDefault();
      var navbar = document.getElementById("navbar");
      if (navbar) {
        if (
          document.body.scrollTop >= 50 ||
          document.documentElement.scrollTop >= 50
        ) {
          navbar.classList.add("is-sticky");
        } else {
          navbar.classList.remove("is-sticky");
        }
      }
    });

    document.querySelector(".currentyear").innerHTML =
      new Date().getFullYear() + " © Velzon - Themesbrand";
  },
};
</script>

<template>
  <div class="layout-wrapper landing">
    <nav class="navbar navbar-expand-lg navbar-landing fixed-top" id="navbar">
      <div class="container">
        <b-link class="navbar-brand" href="/">
          <img
            src="@/assets/images/logo-sm.png"
            class="card-logo card-logo-dark"
            alt="logo dark"
            height="50"
          />
          <img
            src="@/assets/images/logo-sm.png"
            class="card-logo card-logo-light"
            alt="logo light"
            height="17"
          />
        </b-link>
        <button
          class="navbar-toggler py-0 fs-20 text-body"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="mdi mdi-menu"></i>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example"></ul>

          <div class="">
            <b-link
              href="/login"
              style="background-color: #49a6bf"
              class="btn btn-link fw-medium text-decoration-none text-white"
              >Sign in</b-link
            >
          </div>
        </div>
      </div>
    </nav>
    <!-- end navbar -->

    <!-- start hero section -->
    <section class="section pb-0 hero-section" id="hero">
      <div class="bg-overlay bg-overlay-pattern"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-sm-10">
            <div class="text-center mt-lg-5 pt-5">
              <h1 class="display-6 fw-semibold mb-3 lh-base">
                The easiest and most efficient way to monitor and optimise
                traffic with
                <span style="color: #49a6bf">uRAD Smart Traffic </span>
              </h1>
              <p class="lead text-muted lh-base">
                uRAD Smart Traffic are specific solutions in the field of smart
                cities for non-invasive traffic monitoring in urban
                environments. These finished customizable solutions for vehicle
                counting integrate our radars along with additional specific
                software and hardware.
              </p>

              <!-- Ratio Video 21:9 -->
              <div class="text-center mt-lg-5 pt-5">
                <div class="ratio ratio-21x9">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/PJj2ZAx_gio"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>

            <div class="mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel">
              <div class="demo-img-patten-top d-none d-sm-block">
                <img
                  src="@/assets/images/landing/img-pattern.png"
                  class="d-block img-fluid"
                  alt="..."
                />
              </div>
              <div class="demo-img-patten-bottom d-none d-sm-block">
                <img
                  src="@/assets/images/landing/img-pattern.png"
                  class="d-block img-fluid"
                  alt="..."
                />
              </div>
              <div class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-inner shadow-lg p-2 bg-white rounded">
                  <div class="carousel-item active" data-bs-interval="2000">
                    <img
                      src="@/assets/images/demos/platform.png"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
      <div class="position-absolute start-0 end-0 bottom-0 hero-shape-svg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120"
        >
          <g mask='url("#SvgjsMask1003")' fill="none">
            <path
              d="M 0,118 C 288,98.6 1152,40.4 1440,21L1440 140L0 140z"
            ></path>
          </g>
        </svg>
      </div>
      <!-- end shape -->
    </section>
    <!-- end hero section -->

    <!-- start client section -->
    <div class="pt-5 mt-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-5">
              <h5 class="fs-20">Clients around the world trust in uRAD</h5>
              <div class="trusted-client-slider mt-sm-5 mt-4 mb-sm-5 mb-4">
                <swiper
                  class="swiper responsive-swiper rounded gallery-light pb-4"
                  :loop="true"
                  :slidesPerView="3"
                  :spaceBetween="10"
                  :pagination="{
                    el: '.swiper-pagination',
                    clickable: true,
                  }"
                >
                  <swiper-slide>
                    <div class="swiper-slide">
                      <div>
                        <img
                          src="@/assets/images/clients/logobn.png"
                          height="300"
                          width="300"
                          class="mx-auto img-fluid d-block"
                          
                        />
                      </div>
                    </div>
                  </swiper-slide>
                  <!-- end slide item -->
                  <swiper-slide>
                    <div class="swiper-slide">
                      <div>
                        <img
                          src="@/assets/images/clients/cartagena.jpg"
                          height="250"
                          width="250"
                          class="mx-auto img-fluid d-block"
                        />
                      </div>
                    </div>
                  </swiper-slide>
                  <!-- end slide item -->
                  <swiper-slide>
                    <div class="swiper-slide">
                      <div >
                        <img
                          src="@/assets/images/clients/upna.png"
                          
                          class="mx-auto img-fluid d-block"
                        />
                      </div>
                    </div>
                  </swiper-slide>

                  <!-- end slide item -->
                </swiper>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end client section -->

    <!-- start services -->
    <section class="section" id="services">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="text-center mb-5">
              <h1 class="mb-3 ff-secondary fw-semibold lh-base">
                Discover the potential of
                <span style="color: #49a6bf">uRAD Smart Traffic </span>
              </h1>
              <p class="text-muted">
                All our systems are affordable, easy to install and configure,
                very versatile since can be installed in any road, and
                outstandingly accurate, with an effectiveness higher than 99%.
              </p>
            </div>
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->

        <div class="row g-3">
          <div class="col-lg-4">
            <div class="d-flex p-3">
              <div class="flex-shrink-0 me-3">
                <div>
                  <div
                    class="avatar-title bg-transparent text-info rounded-circle"
                  >
                    <i class="las la-car fs-36"></i>
                  </div>
                </div>
              </div>
              <div class="flex-grow-1">
                <h5 class="fs-18">Vehicle Counting</h5>
                <p class="text-muted my-3 ff-secondary">
                  Up to 6 lanes in both directions
                </p>
              </div>
            </div>
          </div>
          <!-- end col -->
          <div class="col-lg-4">
            <div class="d-flex p-3">
              <div class="flex-shrink-0 me-3">
                <div>
                  <div
                    class="avatar-title bg-transparent text-info rounded-circle"
                  >
                    <i class="las la-tachometer-alt fs-36"></i>
                  </div>
                </div>
              </div>
              <div class="flex-grow-1">
                <h5 class="fs-18">Vehicle Velocity</h5>
                <p class="text-muted my-3 ff-secondary">
                  Velocity measurement up to 180 Km/h
                </p>
              </div>
            </div>
          </div>
          <!-- end col -->
          <div class="col-lg-4">
            <div class="d-flex p-3">
              <div class="flex-shrink-0 me-3">
                <div>
                  <div
                    class="avatar-title bg-transparent text-info rounded-circle"
                  >
                    <i class="las la-truck fs-36"></i>
                  </div>
                </div>
              </div>
              <div class="flex-grow-1">
                <h5 class="fs-18">Vehicle Discrimination</h5>
                <p class="text-muted my-3 ff-secondary">
                  Between regular vehicles, long vehicles, bicycles and
                  pedestrians.
                </p>
              </div>
            </div>
          </div>
          <!-- end col -->
          <div class="col-lg-4">
            <div class="d-flex p-3">
              <div class="flex-shrink-0 me-3">
                <div>
                  <div
                    class="avatar-title bg-transparent text-info rounded-circle"
                  >
                    <i class="las la-traffic-light fs-36"></i>
                  </div>
                </div>
              </div>
              <div class="flex-grow-1">
                <h5 class="fs-18">Different Scenarios</h5>
                <p class="text-muted my-3 ff-secondary">
                  Dense Or Light Traffic Scenarios
                </p>
              </div>
            </div>
          </div>
          <!-- end col -->
          <div class="col-lg-4">
            <div class="d-flex p-3">
              <div class="flex-shrink-0 me-3">
                <div>
                  <div
                    class="avatar-title bg-transparent text-info rounded-circle"
                  >
                    <i class="las la-camera-retro fs-36"></i>
                  </div>
                </div>
              </div>
              <div class="flex-grow-1">
                <h5 class="fs-18">Optional Camera</h5>
                <p class="text-muted my-3 ff-secondary">
                  License plate recognition.
                </p>
              </div>
            </div>
          </div>
          <!-- end col -->
          <div class="col-lg-4">
            <div class="d-flex p-3">
              <div class="flex-shrink-0 me-3">
                <div>
                  <div
                    class="avatar-title bg-transparent text-info rounded-circle"
                  >
                    <i class="las la-chalkboard fs-36"></i>
                  </div>
                </div>
              </div>
              <div class="flex-grow-1">
                <h5 class="fs-18">Real Time Web Application With Traffic Statistics</h5>
                <p class="text-muted my-3 ff-secondary">
                  Online platform to visualize real-time statistics.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <!-- end footer -->
  </div>
</template>
